<template>
	<section class="section">
		<base-container>
			<base-row>
				<base-col col="12">
					<faq-section v-for="item in value" :item="item" :key="item.alias"/>
				</base-col>
			</base-row>
		</base-container>
	</section>
</template>
<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import FaqSection from '~/website/front/components/molecules/faq/FaqSection'

export default {
	components: {
		FaqSection
	},
	mixins: [
		pageMixin
	],
	computed: {
		value () {
			return this.page.value.items
		}
	},
	mounted () {
		if (!this.page.query.section) return
		this.$gsap.to(window, { duration: 1, scrollTo: { y: `#${this.page.query.section}`, offsetY: 110 } })
	}
}
</script>
<style lang="scss" scoped>
.section {
	padding: 5rem 0;
	@include media-breakpoint-up(md) {
		padding: 3rem 0;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 0;
	}
}
</style>
