import { Page } from '~/cms/front/core/pages/Page'
import component from './FaqPage.vue'

class FaqPage extends Page {
	static alias = 'faq'

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}

	component = component
}

export { FaqPage }
