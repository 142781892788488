<template>
	<div :id="item.alias" class="wrapper">
		<base-font variant="medium-header">{{item.section}}</base-font>
		<div class="question-wrapper" v-for="item in item.items" :key="item.question">
			<base-font variant="small-header">{{item.question}}</base-font>
			<div v-html="item.answer"></div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		item: Object
	}
}
</script>
<style lang="scss" scoped>

.question-wrapper {
	padding-left: 1rem;
	margin: 2rem 0;
	@include media-breakpoint-up(lg) {
		padding-left: 2rem;
		margin: 0;
	}
}
</style>
